<template>
  <div class="row">
    <div class="form-group col-12 p-2">
      <div class="btn-group float-right">
        <button
          type="button"
          class="btn btn bg-navy"
          data-toggle="modal"
          data-target="#modal_tarifa_tarifario_masiva"
          @click="getDatosModal()"
          v-if="
            $store.getters.can(
              'tif.tarifarios.importTifTarifasMasivas'
            )
          "
        >
          <i class="fas fa-file-upload"></i>
        </button>
        <button
          type="button"
          class="btn btn bg-olive"
          data-toggle="modal"
          data-target="#modal_tarifa_tarifario_actualizacion_masiva"
          @click="getDatosModalMasivos()"
          v-if="
            $store.getters.can('tif.tarifarios.edit') && 
            $parent.form &&
            $parent.form.tarifa_tarifario.length > 0
          "
        >
          <i class="fas fa-sync"></i>
        </button>
        <button
          type="button"
          class="btn btn-sm bg-primary"
          data-toggle="modal"
          data-target="#modal_tarifa_tarifario"
          @click="getDatosModal()"
          v-if="$store.getters.can('tif.tarifarios.crearTarifa')"
        >
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
    <div class="col-12 table-responsive p-0" style="font-size: 0.85em">
      <table
        class="table table-bordered table-striped table-hover text-nowrap table-sm mb-0"
        v-if="$parent.form"
      >
        <thead class="thead-dark">
          <tr>
            <th class="text-center">Turno</th>
            <th class="text-center">Operación</th>
            <th class="text-center">Producto</th>
            <th class="text-center">Tipo Vehículo</th>
            <th class="text-center">Tipo Tarifa</th>
            <th class="text-center">Valor</th>
            <th class="text-center">Fecha Ini</th>
            <th class="text-center">Fecha Fin</th>
            <th class="text-center" style="width: 120px">Acción</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="tarifa in $parent.form.tarifa_tarifario"
            :key="tarifa.id"
          >
            <td>{{ tarifa.turno.nombre }}</td>
            <td>{{ tarifa.nOperacion }}</td>
            <td>{{ tarifa.producto.nombre }}</td>
            <td>{{ tarifa.tipo_vehiculo.nombre }}</td>
            <td>{{ tarifa.nTipoTarifa }}</td>
            <td>
              <div v-if="tarifa.tipo_tarifa == 1">
                <div>
                  <small
                    >Valor Disponible:
                    <strong class="float-right"
                      >${{ tarifa.det_tarifa[0].valor_disp }}</strong
                    ></small
                  >
                </div>
              </div>
              <div v-if="tarifa.tipo_tarifa == 2">
                <div>
                  <small
                    >Costo Fijo:
                    <strong class="float-right"
                      >${{ tarifa.det_tarifa[0].costo_fijo }}</strong
                    ></small
                  >
                </div>
                <div>
                  <small
                    >Costo Variable:
                    <strong class="float-right"
                      >${{ tarifa.det_tarifa[0].costo_variable }}</strong
                    ></small
                  >
                </div>
              </div>
              <div v-if="tarifa.tipo_tarifa == 3">
                <div>
                  <small
                    >Valor Operativo:
                    <strong class="float-right"
                      >${{ tarifa.det_tarifa[0].valor_op }}</strong
                    ></small
                  >
                </div>
                <div>
                  <small
                    >Valor Disponible:
                    <strong class="float-right"
                      >${{ tarifa.det_tarifa[0].valor_disp }}</strong
                    ></small
                  >
                </div>
              </div>
            </td>
            <td class="text-center">{{ tarifa.det_tarifa[0].fecha_ini }}</td>
            <td class="text-center">{{ tarifa.det_tarifa[0].fecha_fin }}</td>
            <td class="text-right">
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-sm bg-info"
                  data-toggle="modal"
                  data-target="#modal_tarifa_tarifario_historico"
                  @click="getDatosHistorico(tarifa)"
                >
                  <i class="fas fa-history"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-sm bg-navy"
                  data-toggle="modal"
                  data-target="#modal_tarifa_tarifario"
                  @click="getDatosModal(tarifa)"
                  v-if="$store.getters.can('tif.tarifarios.crearTarifa')"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="destroy(tarifa)"
                  v-if="
                    $store.getters.can('tif.tarifarios.eliminarTarifa') &&
                    $parent.estado == 1
                  "
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="modal fade" id="modal_tarifa_tarifario_actualizacion_masiva">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Actualización masiva de tarifas</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalMasivo"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-3">
                <label>Fecha Inicio</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="form_masivo.fecha_ini"
                  :class="
                    $v.form_masivo.fecha_ini.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="validarFechasMasivos()"
                />
              </div>
              <div class="col-md-3">
                <label>Fecha Fin</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="form_masivo.fecha_fin"
                  :class="
                    $v.form_masivo.fecha_fin.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="validarFechasMasivos()"
                />
              </div>
              <div class="col-md-3">
                <label>Incremento</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form_masivo.incremento"
                  :class="$v.form_masivo.incremento.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between"
            v-if="
              $store.getters.can('tif.tarifarios.edit') && 
              !$v.form_masivo.$invalid
            "
          >
            <button
              type="button"
              class="btn btn-primary"
              @click="saveMasivos()"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="modal_tarifa_tarifario_masiva">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Tarifa Masiva</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-4">
                <label class="col-md-12">Plantilla</label>
                <button class="btn bg-primary" @click="downloadWithAxios">
                  <i class="fas fa-file-download"></i> Descargar
                </button>
              </div>

              <div class="form-group col-md-8">
                <label class="col-md-12">Importar Plantilla</label>
                <div class="card text-center card-footer text-muted">
                  <input
                    :disabled="$parent.definitivo == 1"
                    type="file"
                    id="file"
                    ref="file"
                    @change="elegirDirectorio()"
                    accept=".xlsx, .CSV"
                    class="form-control btn btn-secundary"
                  />
                  <button
                    @click="importarMasiva()"
                    class="btn btn bg-navy"
                    v-if="activo == 1"
                  >
                    <i class="fas fa-file-upload"></i> Subir
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              @click="save()"
              v-if="
                $store.getters.can('tif.tarifarios.importTifTarifasMasivas') &&
                !$v.form.$invalid
              "
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div class="modal fade" id="modal_tarifa_tarifario">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Tarifa</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-12">
                <label>Turno</label>
                <select
                  v-if="form.accion == 2"
                  class="form-control form-control-sm"
                  v-model="form.turno_id"
                  :class="$v.form.turno_id.$invalid ? 'is-invalid' : 'is-valid'"
                  @change="validarRepetidos"
                  disabled
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="turno in listasForms.turnos"
                    :key="turno.id"
                    :value="turno.id"
                  >
                    {{ turno.nombre }}
                  </option>
                </select>
                <v-select
                  v-else
                  :class="[
                    $v.form.turno_id.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="turno"
                  placeholder="Seleccione..."
                  label="nombre"
                  class="form-control form-control-sm p-0"
                  multiple
                  :options="listasForms.turnos"
                  @input="selectTurnos()"
                ></v-select>
              </div>
              <div class="form-group col-md-12">
                <label>Operación</label>
                <select
                  v-if="form.accion == 2"
                  class="form-control form-control-sm"
                  v-model="form.operacion"
                  :class="
                    $v.form.operacion.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  disabled
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="operacion in listasForms.operaciones"
                    :key="operacion.numeracion"
                    :value="operacion.numeracion"
                  >
                    {{ operacion.descripcion }}
                  </option>
                </select>
                <v-select
                  v-else
                  :class="[
                    $v.form.operacion.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="tif_operacion"
                  placeholder="Seleccione..."
                  label="descripcion"
                  class="form-control form-control-sm p-0"
                  multiple
                  :options="listasForms.operaciones"
                  @input="selectOperaciones()"
                ></v-select>
              </div>
              <div class="form-group col-md-12">
                <label>Producto</label>
                <select
                  v-if="form.accion == 2"
                  class="form-control form-control-sm"
                  v-model="form.producto_id"
                  :class="
                    $v.form.producto_id.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="validarRepetidos"
                  disabled
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="prod in listasForms.productos"
                    :key="prod.id"
                    :value="prod.id"
                  >
                    {{ prod.nombre }}
                  </option>
                </select>
                <v-select
                  v-else
                  :class="[
                    $v.form.producto_id.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="producto"
                  placeholder="Seleccione..."
                  label="nombre"
                  class="form-control form-control-sm p-0"
                  :filterable="true"
                  multiple
                  :options="listasForms.productos"
                  @input="selectProductos()"
                ></v-select>
              </div>
              <div class="form-group col-md-3">
                <label>Tipo Vehículo</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.tipo_vehiculo_id"
                  :class="
                    $v.form.tipo_vehiculo_id.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @change="validarRepetidos"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo in listasForms.tipo_vehiculos"
                    :key="tipo.id"
                    :value="tipo.id"
                  >
                    {{ tipo.nombre }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label>Fecha Inicio</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="form.fecha_ini"
                  :class="
                    $v.form.fecha_ini.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="validarFechas()"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Fecha Fin</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="form.fecha_fin"
                  :class="
                    $v.form.fecha_fin.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="validarFechas()"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Tipo Tarifa</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.tipo_tarifa"
                  :class="
                    $v.form.tipo_tarifa.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="resetValues()"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo in listasForms.tipo_tarifas"
                    :key="tipo.numeracion"
                    :value="tipo.numeracion"
                  >
                    {{ tipo.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-3" v-if="form.tipo_tarifa == 3">
                <label>Valor Operativo</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form.valor_op"
                  :class="$v.form.valor_op.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
              <div
                class="form-group col-md-3"
                v-if="form.tipo_tarifa == 3 || form.tipo_tarifa == 1"
              >
                <label>Valor Disponible</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form.valor_disp"
                  :class="
                    $v.form.valor_disp.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
              <div class="form-group col-md-3" v-if="form.tipo_tarifa == 2">
                <label>Costo Fijo</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form.costo_fijo"
                  :class="
                    $v.form.costo_fijo.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
              <div class="form-group col-md-3" v-if="form.tipo_tarifa == 2">
                <label>Costo Variable</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form.costo_variable"
                  :class="
                    $v.form.costo_variable.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              @click="valTurnoLiqui()"
              v-if="
                $store.getters.can('tif.tarifarios.edit') && !$v.form.$invalid
              "
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div class="modal fade" id="modal_tarifa_tarifario_historico">
      <div class="modal-dialog modal-xl" v-if="datos_tarifa_historico">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Histórico Tarifa</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              class="position-relative p-3 m-2 bg-light"
              style="font-size: 0.85em"
            >
              <div class="row">
                <div class="col-sm-4">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.turno }}
                    </h5>
                    <span class="description-text text-secondary">Turno</span>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.operacion }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Operación</span
                    >
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.producto }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Producto</span
                    >
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.tipo_vehiculo }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Tipo Vehículo</span
                    >
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.fecha_ini }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Fecha Inicial</span
                    >
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.fecha_fin }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Fecha Final</span
                    >
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.nombre_tipo_tarifa }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Tipo Tarifa</span
                    >
                  </div>
                </div>
                <!-- Datos de tarifas Pozoz Satelites -->
                <div
                  class="col-sm-12"
                  v-if="datos_tarifa_historico.tipo_tarifa == 1"
                >
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.valor_disp }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Valor Disponible</span
                    >
                  </div>
                </div>
                <!-- Datos de tarifas Fijo + Variable -->
                <div
                  class="col-sm-6"
                  v-if="datos_tarifa_historico.tipo_tarifa == 2"
                >
                  <div class="description-block">
                    <h5 class="description-header">
                      ${{ datos_tarifa_historico.costo_fijo }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Costo Fijo</span
                    >
                  </div>
                </div>
                <div
                  class="col-sm-6"
                  v-if="datos_tarifa_historico.tipo_tarifa == 2"
                >
                  <div class="description-block">
                    <h5 class="description-header">
                      ${{ datos_tarifa_historico.costo_variable }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Costo Variable</span
                    >
                  </div>
                </div>
                <!-- Datos de tarifas Tiempos -->
                <div
                  class="col-sm-6"
                  v-if="datos_tarifa_historico.tipo_tarifa == 3"
                >
                  <div class="description-block">
                    <h5 class="description-header">
                      ${{ datos_tarifa_historico.valor_op }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Valor Operativo</span
                    >
                  </div>
                </div>
                <div
                  class="col-sm-6"
                  v-if="datos_tarifa_historico.tipo_tarifa == 3"
                >
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.valor_disp }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Valor Disponible</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body table-responsive">
            <table
              class="table table-bordered table-striped table-hover text-nowrap table-sm"
            >
              <thead class="thead-dark">
                <tr class="text-center">
                  <th class="col-1">Estado</th>
                  <th class="col-2">Valor</th>
                  <th>Fecha Ini</th>
                  <th>Fecha Fin</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="tarifa in tarifa_historico" :key="tarifa.id">
                  <td class="text-center">
                    <span
                      class="badge"
                      :class="
                        tarifa.estado == 1 ? 'badge-success' : 'badge-danger'
                      "
                      >{{ tarifa.nEstado }}</span
                    >
                  </td>
                  <td>
                    <div v-if="tarifa.valor_op">
                      <small
                        >Valor Operativo:
                        <strong class="float-right">${{ tarifa.valor_op }}</strong></small
                      >
                    </div>
                    <div v-if="tarifa.valor_disp">
                      <small
                        >Valor Disponible:
                        <strong class="float-right">${{ tarifa.valor_disp }}</strong></small
                      >
                    </div>
                    <div v-if="tarifa.costo_fijo">
                      <small
                        >Costo Fijo:
                        <strong class="float-right">${{ tarifa.costo_fijo }}</strong></small
                      >
                    </div>
                    <div v-if="tarifa.costo_variable">
                      <small
                        >Costo Variable:
                        <strong class="float-right">${{ tarifa.costo_variable }}</strong></small
                      >
                    </div>
                  </td>
                  <td class="text-center">{{ tarifa.fecha_ini }}</td>
                  <td class="text-center">{{ tarifa.fecha_fin }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import vSelect from "vue-select";
export default {
  name: "TarifasTarifarios",
  components: {
    vSelect,
  },
  data() {
    return {
      bloque_id: null,
      activo: 0,
      accion: 0,
      tarifario_id: this.$parent.form.id,
      turno: null,
      producto: null,
      tif_operacion: null,
      ejecucion: null,
      form: {
        id: null,
        tif_tarifario_id: null,
        turno_id: null,
        operacion: null,
        producto_id: null,
        tipo_vehiculo_id: null,
        tipo_tarifa: null,
        fecha_ini: null,
        fecha_fin: null,
        valor_op: null,
        valor_disp: null,
        costo_fijo: null,
        costo_variable: null,
        accion: 1,
      },
      form_masivo: {
        tif_tarifario_id: null,
        fecha_ini: null,
        fecha_fin: null,
        incremento: null,
      },
      tarifaModal: [],
      listasForms: {
        turnos: [],
        operaciones: [],
        productos: [],
        tipo_vehiculos: [],
        tipo_tarifas: [],
      },
      datos_tarifa_historico: [],
      tarifa_historico: [],
    };
  },
  validations() {
    switch (this.form.tipo_tarifa) {
      case 1:
        return {
          form: {
            turno_id: {
              required,
            },
            operacion: {
              required,
            },
            producto_id: {
              required,
            },
            tipo_vehiculo_id: {
              required,
            },
            tipo_tarifa: {
              required,
            },
            fecha_ini: {
              required,
            },
            fecha_fin: {
              required,
            },
            valor_disp: {
              required,
            },
          },
          form_masivo: {
            tif_tarifario_id: {
              required,
            },
            fecha_ini: {
              required,
            },
            fecha_fin: {
              required,
            },
            incremento: {
              required,
            },
          },
        };

      case 2:
        return {
          form: {
            turno_id: {
              required,
            },
            operacion: {
              required,
            },
            producto_id: {
              required,
            },
            tipo_vehiculo_id: {
              required,
            },
            tipo_tarifa: {
              required,
            },
            fecha_ini: {
              required,
            },
            fecha_fin: {
              required,
            },
            costo_fijo: {
              required,
            },
            costo_variable: {
              required,
            },
          },
          form_masivo: {
            tif_tarifario_id: {
              required,
            },
            fecha_ini: {
              required,
            },
            fecha_fin: {
              required,
            },
            incremento: {
              required,
            },
          },
        };

      case 3:
        return {
          form: {
            turno_id: {
              required,
            },
            operacion: {
              required,
            },
            producto_id: {
              required,
            },
            tipo_vehiculo_id: {
              required,
            },
            tipo_tarifa: {
              required,
            },
            fecha_ini: {
              required,
            },
            fecha_fin: {
              required,
            },
            valor_op: {
              required,
            },
            valor_disp: {
              required,
            },
          },
          form_masivo: {
            tif_tarifario_id: {
              required,
            },
            fecha_ini: {
              required,
            },
            fecha_fin: {
              required,
            },
            incremento: {
              required,
            },
          },
        };

      default:
        return {
          form: {
            turno_id: {
              required,
            },
            operacion: {
              required,
            },
            producto_id: {
              required,
            },
            tipo_vehiculo_id: {
              required,
            },
            tipo_tarifa: {
              required,
            },
            fecha_ini: {
              required,
            },
            fecha_fin: {
              required,
            },
          },
          form_masivo: {
            tif_tarifario_id: {
              required,
            },
            fecha_ini: {
              required,
            },
            fecha_fin: {
              required,
            },
            incremento: {
              required,
            },
          },
        };
    }
  },
  methods: {
    init() {
      this.bloque_id = this.$parent.form.bloque_id;
      this.getTurnos();
      this.getProductos();
      this.getOperaciones();
      this.getTiposVehiculos();
      this.getTiposTarifas();
    },

    async elegirDirectorio() {
      this.form.file = await this.$refs.file.files[0];
      this.activo = 1;
    },

    getTurnos() {
      const filtros = {
        linea_negocio_id: [3],
        estado: 1,
      };
      axios
        .get("/api/admin/turnos/lista", {
          params: filtros,
        })
        .then((response) => {
          this.listasForms.turnos = response.data;
        });
    },

    getProductos() {
      axios.get("/api/tif/operaciones/operationsProductos").then((response) => {
        this.listasForms.productos = response.data;
      });
    },

    getOperaciones() {
      axios.get("/api/lista/120").then((response) => {
        this.listasForms.operaciones = response.data;
      });
    },

    getTiposVehiculos() {
      axios
        .get("api/admin/tiposVehiculos/lista", {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then((response) => {
          this.listasForms.tipo_vehiculos = response.data;
        });
    },

    getTiposTarifas() {
      axios.get("/api/lista/96").then((response) => {
        this.listasForms.tipo_tarifas = response.data;
      });
    },

    selectTurnos() {
      this.form.turno_id = null;
      if (this.turno) {
        this.form.turno_id = this.turno.map((t) => t.id);
        this.validarRepetidos();
      }
    },

    selectOperaciones() {
      this.form.operacion = null;
      if (this.tif_operacion) {
        this.form.operacion = this.tif_operacion.map((o) => o.numeracion);
      }
    },

    selectProductos() {
      this.form.producto_id = null;
      if (this.producto) {
        this.form.producto_id = this.producto.map((p) => p.id);
        this.validarRepetidos();
      }
    },

    getDatosModal(tarifa = null) {
      this.$parent.cargando = true;
      this.accion = 1;
      this.resetForm();
      if (tarifa) {
        const det_tarifa = tarifa.det_tarifa[0];
        this.tarifaModal = det_tarifa;
        this.accion = 2;
        this.form = {
          id: tarifa.id,
          tif_tarifario_id: this.$parent.form.id,
          turno_id: tarifa.turno_id,
          operacion: tarifa.operacion,
          producto_id: tarifa.producto_id,
          tipo_vehiculo_id: tarifa.tipo_vehiculo_id,
          tipo_tarifa: tarifa.tipo_tarifa,
          fecha_ini: null,
          fecha_fin: null,
          valor_op: det_tarifa.valor_op,
          valor_disp: det_tarifa.valor_disp,
          costo_fijo: det_tarifa.costo_fijo,
          costo_variable: det_tarifa.costo_variable,
          accion: 2,
        };
      }
      this.$parent.cargando = false;
    },

    getDatosModalMasivos(){
      this.resetForm();
      this.form_masivo = {
        tif_tarifario_id: this.$parent.form.id,
        fecha_ini: null,
        fecha_fin: null,
        incremento: null,
      }
    },

    getDatosHistorico(tarifa) {
      this.tarifa_historico = null;
      this.datos_tarifa_historico = {
        turno: tarifa.turno.nombre,
        operacion: tarifa.nOperacion,
        producto: tarifa.producto.nombre,
        fecha_ini: tarifa.det_tarifa[0].fecha_ini,
        fecha_fin: tarifa.det_tarifa[0].fecha_fin,
        tipo_vehiculo: tarifa.tipo_vehiculo.nombre,
        tipo_tarifa: tarifa.tipo_tarifa,
        nombre_tipo_tarifa: tarifa.nTipoTarifa,
        nombre_tarifa: tarifa.det_tarifa[0].nombre_tarifa,
        costo_fijo: tarifa.det_tarifa[0].costo_fijo,
        costo_variable: tarifa.det_tarifa[0].costo_variable,
        valor_disp: tarifa.det_tarifa[0].valor_disp,
        valor_op: tarifa.det_tarifa[0].valor_op,
      };
      this.tarifa_historico = tarifa.det_tarifa;
    },

    resetForm() {
      this.form = {
        id: null,
        tif_tarifario_id: this.$parent.form.id,
        turno_id: null,
        operacion: null,
        producto_id: null,
        tipo_vehiculo_id: null,
        tipo_tarifa: null,
        fecha_ini: null,
        fecha_fin: null,
        valor_op: null,
        valor_disp: null,
        costo_fijo: null,
        costo_variable: null,
        accion: 1,
      };
      this.form_masivo = {
        tif_tarifario_id: null,
        fecha_ini: null,
        fecha_fin: null,
        incremento: null,
      }
      this.turno = null;
      this.tif_operacion = null;
      this.producto = null;
    },

    resetValues() {
      this.form.valor_op = null;
      this.form.valor_disp = null;
      this.form.costo_fijo = null;
      this.form.costo_variable = null;
      this.validarRepetidos();
    },

    async validarFechas() {
      // Se valida que la fecha inicial no sea mayor a la fecha final
      await this.validaFechasVigencia();
    },

    validarFechasMasivos() {
      if (this.form_masivo.fecha_ini && this.form_masivo.fecha_fin) {
        if (!this.validaFechas(this.form_masivo.fecha_ini, this.form_masivo.fecha_fin)) {
          this.form_masivo.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Inicio de la Tarifa no puede ser mayor a la Fecha Fin...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          return false;
        }
      }
      if (this.form_masivo.fecha_ini && this.$parent.form.tarifa_tarifario[0].det_tarifa[0].fecha_ini) {
        if (!this.validaFechas(this.$parent.form.tarifa_tarifario[0].det_tarifa[0].fecha_ini, this.form_masivo.fecha_ini)) {
          this.form_masivo.fecha_ini = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Inicio de la Tarifa no puede ser mayor a la Fecha Inicial de la Tarifa Anterior...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          return false;
        }
      }
    },

    async validaFechasVigencia() {
      if (this.form.fecha_ini && this.form.fecha_fin) {
        if (!this.validaFechas(this.form.fecha_ini, this.form.fecha_fin)) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Inicio de la Tarifa no puede ser mayor a la Fecha Fin...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    //Se valida al cambiar la tarifa que no exista un turno liquidado
    async valTurnoLiqui() {
      this.ejecucion = false;
      if (this.form.accion == 2) {
        await axios({
          method: "GET",
          url: "/api/tif/tarifarios/valTurnoLiqui",
          params: this.form,
        }).then(async (response) => {
          if (Object.entries(response.data).length > 0) {
            await this.$swal({
              title: "No se puede cambiar el valor",
              text: "Ya existe un turno liquidado con esta tarifa, desea crear un histórico nuevo ?.",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "OK",
            }).then(async (result) => {
              this.ejecucion = result.value;
              if (result.value) {
                await this.$swal({
                  icon: "success",
                  title: "Es apto para crear un nuevo histórico.",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                });
                this.save();
              } else {
                this.$refs.closeModal.click();
              }
            });
          } else {
            if (this.ejecucion == false) {
              this.save();
            }
          }
        });
      } else {
        this.save();
      }
    },

    validaFechas(fecha_menor, fecha_mayor) {
      fecha_menor = new Date(fecha_menor);
      fecha_mayor = new Date(fecha_mayor);
      // Se valida que la fecha inicial sea menor que la fecha final
      if (fecha_menor > fecha_mayor) {
        return false;
      }
      return true;
    },

    validarRepetidos() {
      if (
        this.form.turno_id &&
        this.form.operacion &&
        this.form.producto_id &&
        this.form.tipo_vehiculo_id &&
        this.form.tipo_tarifa
      ) {
        const tarifas = this.$parent.form.tarifa_tarifario;
        let flag = false;
        if (tarifas != undefined) {
          tarifas.forEach((tarifa) => {
            if (
              this.form.turno_id == tarifa.turno_id &&
              this.form.operacion == tarifa.operacion &&
              this.form.producto_id == tarifa.producto_id &&
              this.form.tipo_vehiculo_id == tarifa.tipo_vehiculo_id &&
              this.form.tipo_tarifa == tarifa.tipo_tarifa
            ) {
              flag = true;
            }
          });
        }

        if (flag) {
          this.form.tipo_tarifa = null;
          this.$swal({
            icon: "error",
            title: `Ya existe una tarifa con los parámetros seleccionados...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    save() {
      this.$parent.cargando = true;
      if (!this.$v.form.$invalid) {
        axios({
          method: "POST",
          url: "/api/tif/tarifarios/tarifasTarifarios",
          data: this.form,
        })
          .then((response) => {
            this.$parent.cargando = false;
            this.$parent.form.tarifa_tarifario = response.data.tarifas;
            this.$refs.closeModal.click();

            this.$swal({
              icon: "success",
              title: "La tarifa se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.resetForm();
          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroy(tarifa) {
      this.$swal({
        title: "Está seguro de eliminar esta Tarifa?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.$parent.cargando = true;
          axios
            .delete("/api/tif/tarifarios/tarifasTarifarios/" + tarifa.id)
            .then((response) => {
              this.$parent.cargando = false;
              this.$parent.form.tarifa_tarifario = response.data.tarifas;
              this.$swal({
                icon: "success",
                title: "Se eliminó la Tarifa exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    saveMasivos(){
      this.$parent.cargando = true;
      if (!this.$v.form_masivo.$invalid) {
        axios({
          method: "POST",
          url: "/api/tif/tarifarios/tarifasMasivo",
          data: this.form_masivo,
        })
          .then((response) => {
            this.$parent.cargando = false;
            this.$parent.form.tarifa_tarifario = response.data.tarifas;
            // this.$refs.closeModalMasivo.click();
            this.$swal({
              icon: "success",
              title: "La tarifa se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            // this.resetForm();
          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    downloadWithAxios() {
      this.$parent.cargando = true;
      axios({
        method: "GET",
        url: "/api/tif/tarifarios/exportMasiva",
        params: this.tarifario_id,
      })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    async importarMasiva() {
      //this.$parent.cargando = true;
      let formData = new FormData();
      formData.append("file", this.form.file);
      formData.append("tif_tarifario_id", this.$parent.form.id);
      formData.append("bloque_id", this.$parent.form.bloque_id);
      await axios
        .post("/api/tif/tarifarios/importMasiva", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.status == 0) {
            this.$swal({
              icon: "error",
              title: res.data.msg,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
          } else {
            this.$refs.closeModal1.click();
            this.limpiarModal();
            this.activo = 0;
            this.$swal({
              icon: "success",
              title: "El cargue de la plantilla realizo exitosamente",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.$parent.init();

            this.$parent.form.tarifa_tarifario = res.data.tarifario.tarifa;
            this.$parent.cargando = false;
          }
        });
    },

    async limpiarModal() {
      this.form.file = null;
      document.getElementById("file").value = "";
    },
  },
  mounted() {
    this.init();
  },
};
</script>
